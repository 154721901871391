.mainSidebar_mobile{
    display: none;

}


/* Добавим стили для анимации появления и скрытия */

.sidebar-mobile {
    position: fixed;
    top: -100vh; /* начальная позиция */
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #201f20;
    box-shadow: 3px 3px 20px rgb(32, 31, 32, 0.3);

    transition: top 0.5s ease-in-out; /* плавный переход */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-mobile.open {
    top: 0; /* конечная позиция */
}





/* Прячем Sidebar и показываем бургер-меню на мобильных устройствах */
@media (max-width: 768px) {
    .footer-admin{
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100000;
        position: fixed;
        top: 0;
        width: 100vw;
    }

    .mainSidebar_mobile{
        
        display: block;
    
    }

    .navigation-admin{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex: 1; /* Основное содержимое занимает доступное пространство */
        overflow-y: auto; 
        padding: 50px 0;
        width: 100vw;
    }
    
}


@media (max-width: 420px) {

    .mainAdmin-container{
        padding: 20px 0;
    }
    .mini-contianer{
        flex-direction: column-reverse;
    }
    .modalExcel{
        left: 50%;
        width: 95%;
        transform: translateX(-50%);
    }
    .users-container{
        padding: 50px 5px;
    }
    .section-my-cargo{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;

    }
    .my-cargo-flex2{
        flex-direction: column;
        align-items: center;
    }
    .status-list{
        width: 90%;
        margin:  20px 0;
    }
    .banner-list{
        margin: 20px 0;
    }
    .banners{
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .banner{
        margin: 10px 0;
    }
    .partner{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 40px 0 ;
        padding: 0;
    }
    .bonus-table th, .bonus-table td{
        padding: 5px;
    }
  
}

